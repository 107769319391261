import React, { Component } from 'react'

//import { getLocationsByID, postLocationsByID, subscribeToMqtt } from '../store/ilb/actions'
//import { logout } from '../store/auth/actions'
import autoBind from 'react-autobind'

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import {
  Route,
  Link,
  Switch
} from 'react-router-dom'

import InstrumentManager from './apollo/InstrumentManager'
import { connect } from 'mqtt'
import { Query } from 'react-apollo'
import apolloClient from '../apollo/client'
import { GET_USER } from '../apollo/queries/authentication'
import { ADD_MQTT_MESSAGE } from '../apollo/queries/mqtt'
import DataExplorer from './apollo/DataExplorer'





const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  appBar: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});





function subscribeToMqtt(){
    var client = connect('wss://ovh.pandonia-global-network.org:443/mqtt', {username: 'webstatus', password: 'JovTegOid8'});

    const topic = 'Pandora/monitoring/live/#'
    client.on('connect', ()=>{
        client.subscribe(topic, null)
    })


    client.on('message', (topic, message) => {
        apolloClient.mutate({mutation: ADD_MQTT_MESSAGE, variables: {topic: topic, message: message.toString()} })
    })
}



class ILB extends Component {

    constructor(props){
        super(props)
        autoBind(this)
    }

    componentDidMount(){
        //this.props.dispatch(subscribeToMqtt())
        document.title = "BlickM"
        //subscribeToMqtt()
    }


    logout(){
        localStorage.removeItem('JWT')
        window.location.reload()
    }

    render(){

        const { classes } = this.props
        return (

         <Query query={GET_USER}>
          {({loading, error, data}) => {

            if(loading || error){
              console.log(error)
              return("loading")
            }

            return(

            <div className={classes.layout}>
            <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography className={classes.flex} variant="h5" color="inherit">
                  Hello, {data.user.name}
                </Typography>
                <Button className={classes.menuButton}
                  onClick={this.logout}
                  color="inherit"
                >
                Logout
                </Button>


            </Toolbar>
            </AppBar>

          







            <Route exact path="/" 
                render={(props) => <InstrumentManager {...props} user={data.user}/> }
            />

            </div>

            )

          }}

            </Query>
        )
    }
}


/*const mapStateToProps = (state) => {
  return {
    user: auth.getUser(state)
  }
}*/

export default withStyles(styles)(ILB)