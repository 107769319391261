//export const DJANGO_URL = 'http://localhost:8000/'
export const DJANGO_URL = 'https://ovh.pandonia-global-network.org/'
export const MQTT = false


export const STATUS_COLORS = {                                                                                                                                                                                                                                                                              
    "laboratory": "#4c7fcc",                                                                                                                                                                                                                                                   
    "out of operation": "#997f7f",                                                                                                                                                                                                                                             
    "hold due to issue": "#ccb24c",                                                                                                                                                                                                                                            
    "intensive care": "#337f33",                                                                                                                                                                                                                                               
    "testing": "#7f997f",                                                                                                                                                                                                                                                      
    "operational": "#7fcc33",
    "operational with issue": "#c7ea46",
    "maintenance": '#919191',
    "action by SciGlob needed": '#919191',
    "undefined": "#292828"
}

export const STATUS_SORTING = {
  "intensive care": 0,
  "operational": 1,
  "operational with issue": 1.5,
  "laboratory": 2,
  "maintenance": 3,
  "testing": 4,
  "out of operation": 5,
  "hold due to issue": 6,
  "action by SciGlob needed": 7,
  "undefined":7.5,
  "None": 8
}

export const ISSUE_COLORS = {
    //hw
    "hw.hs": "#e56666",
    "hw.tr": "#e54c99",
    "hw.fi": "#cc66b2",
    "hw.sp": "#e57f7f",
    "hw.tc": "#597fe5",
    "hw.rb": "#3399f2",
    "hw.ps": "#e57f33",
    "hw.pc": "#e56619",
    "hw.ap": "#4cb2b2",
    "hw.su": "#7f99e5",

    //sw
    "sw.os": "#7f99cc",
    "sw.bo": "#72bfa5",
    "sw.bf": "#99bfb2",
    "sw.bm": "#4cb2b2",
    "sw.bp": "#7f99e5",
    "sw.rc": "#72b2cc",

    //cn
    "cn.po": "#222622",
    "cn.it": "#9c7140",

    //Others
    "unknown": "lightgrey"
}

export const COLORS = ['#1561D1','#3E6AD2','#5772D0','#6C7BCD','#7E84C7','#8F8DC0','#9F97B7','#ADA1AC','#BAAB9E']
//export const COLORS = ['#034498','#2C52A8','#4861B5','#6171BF','#7A81C5','#9391C8','#AAA3C7','#C2B5C3','#D8C7BA']
//export const COLORS = ['#0D3E6D','#274D7D','#3E5C8B','#546C97','#6C7BA0','#848CA7','#9C9CAB','#B5ADAD','#CDBEAB']

//export const Q_COLORS = [COLORS[0], COLORS[5], COLORS[8]]
export const Q_COLORS = [COLORS[6], COLORS[4], COLORS[0]]